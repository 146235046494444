import { Swiper, Pagination, Navigation, EffectFade, Autoplay } from 'swiper';

Swiper.use([Navigation, Pagination, EffectFade, Autoplay]);

const Sliders = () => {
  const swipers = document.querySelectorAll('.swiper');
  if (!swipers.length) {
    return false;
  }
  swipers.forEach((el) => {
    // eslint-disable-next-line
    const rand = el.dataset.rand == true;
    let autoplay = false;
    // eslint-disable-next-line
    if (el.dataset.autoplay == true) {
      autoplay = {
        delay: 3500,
        disableOnInteraction: false,
      };
    }
    new Swiper(el, {
      // Optional parameters
      direction: 'horizontal',
      autoplay: autoplay,
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      spaceBetween: 0,
      loop: true,
      initialSlide: rand ? Math.floor(Math.random() * 5) : 0,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  });
};

export default Sliders;
