const ToggleHeaderEntries = () => {
  const links = document.querySelectorAll('.js-header-nested-entry');

  if (!links.length) {
    return false;
  }

  links.forEach((link) => {
    const icon = link.querySelector('.js-header-nested-entry__icon');
    const children = link.querySelector('.js-header-nested-entry__children');

    if (icon === null || children === null) {
      return false;
    }

    icon.addEventListener('click', () => {
      link.classList.toggle('is-active');
    }, true);
  });
};

export default ToggleHeaderEntries;
