const handleClick = (e) => {
  const button = e.target;
  const menuItem = button.closest('.menu-nav__item');
  const siblings = button.closest('.js-menu-nav').querySelectorAll('.menu-nav__item');
  menuItem.classList.toggle('active');
  siblings.forEach((el) => {
    if (el !== menuItem) {
      el.classList.remove('active');
    }
  });
  window.addEventListener('click', (e) => {
    const parentMenuItem = e.target.closest('.menu-nav__item');
    if (parentMenuItem !== menuItem) {
      menuItem.classList.remove('active');
    }
  });
};

const Dropnav = () => {
  const toggles = document.querySelectorAll('.js-nav-link');
  if (!toggles) return;
  toggles.forEach((el) => {
    el.addEventListener('click', handleClick);
  });
};

export default Dropnav;
