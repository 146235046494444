export default () => {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const links = document.querySelectorAll('[data-map-coords]');

  links.forEach((link) => {
    const coords = link.dataset.mapCoords.split(',');
    const zoom = link.dataset.mapZoom;
    const lat = coords[0];
    const lng = coords[1];
    if (isIOS) {
      link.href = `maps://maps.apple.com/maps?q=${lat},${lng}&amp;ll=${lat},${lng}&amp;zoom=${zoom}`;
    }
  });
};
