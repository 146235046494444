import MoveTo from 'moveto';

export default () => {
  const textLinks = document.querySelectorAll('a');
  const anchorTextLinks = [...textLinks].filter((link) => {
    return link.href.indexOf('#') !== -1 && link.href.indexOf('#!') === -1 && link.href.indexOf('/#') === -1;
  });

  if (!anchorTextLinks.length) {
    return false;
  }
  // eslint-disable-next-line
  for (const link of anchorTextLinks) {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      const targetId = link.href.split('#').pop();
      const moveTo = new MoveTo({
        tolerance: 140,
      });
      const target = document.getElementById(targetId);
      // eslint-disable-next-line
      if (target != null) {
        moveTo.move(target);
      }
    });
  }
};
