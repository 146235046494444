// lazyload config settings
import 'picturefill';
import 'lazysizes';
import 'url-polyfill';
import * as Sentry from '@sentry/browser';
import objectFitImages from 'object-fit-images';
import Plyr from 'plyr';
import AnimateLinks from './components/AnimateLinks';
import CopyLink from './components/CopyLink';
import Dropnav from './components/Dropnav';
import HeaderFix from './components/HeaderFix';
import MapLink from './components/MapLink';

// import styles
import '../stylesheets/styles.scss';
import '../stylesheets/components/mailplus.css';
import {
  HandleMenuOverlayEnter,
  HandleMenuOverlayLeave,
} from './components/MenuOverlay';
import Sliders from './components/Slider';
import ToggleHeaderEntries from './components/ToggleHeaderEntries';

import s from './store';

Sentry.init({
  dsn: 'https://65a1b70380864a09b03312fd0b816488@sentry.yournext.agency/67',
});

// lazyload config settings
window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.loadMode = 1;

// import JS components as splitted chunks
const Autocomplete = () => import(/* webpackChunkName: "js-component-autocomplete" */ './components/Autocomplete/index');
const HeaderHide = () => import(/* webpackChunkName: "js-component-header-hide" */ './components/HeaderHide/index');
const ImageUncover = () => import(/* webpackChunkName: "js-component-image-uncover" */ './components/ImageUncover/index');

// App main
const main = async () => {
  // Async load the vue module
  const { default: Vue } = await import('vue');

  // Create our vue instance
  const vm = new Vue({
    el: '#app',
    delimiters: ['${', '}'],
    components: {
      'articles-list': () => import('./components/ArticlesList.vue'),
      'events-list': () => import('./components/EventsList.vue'),
      'verdicts-list': () => import('./components/VerdictsList.vue'),
      // delete mobile-nav after release
      'mobile-nav': () => import('./components/MobileNav.vue'),
      'mobile-nav-header': () => import('./components/MobileNavHeader.vue'),
      'newsletter-call-to-action': () => import('./components/NewsletterCallToAction.vue'),
      // delete search-box after release
      'search-box': () => import('./components/SearchBox.vue'),
      'search-box-header': () => import('./components/SearchBoxHeader.vue'),
    },
    data() {
      return {
        privateState: {},
        sharedState: s.state,
      };
    },
    mounted() {
      if (s.debug) console.log('APP INIT');
    },
    methods: {
      toggleMenuOverlay: () => s.toggleMenuOverlayAction(),
      menuOverlayEnter: (el, done) => {
        HandleMenuOverlayEnter(el, done);
      },
      menuOverlayLeave: (el, done) => HandleMenuOverlayLeave(el, done),
      changeFilters: (el) => s.changeFiltersAction(el),
    },
  });

  Autocomplete({
    inputEl: '.js-autocomplete--search',
    datalistEl: '.js-autocomplete--list',
    form: '.js-autocomplete--form',
    breadcrum: '.js-autocomplete--breadcrum',
    suggestionsList: '.js-autocomplete--suggestions-list',
  });

  HeaderHide({
    el: '.js-header-hide',
  });

  ImageUncover({
    imageContainers: '.js-image--container',
  });

  return vm;
};

// Execute async function
main().then((vm) => {
  if (s.debug) console.log(vm);

  // Close overlay through ESC key
  document.addEventListener('keyup', (e) => {
    if (e.keyCode !== 27) return false;
    if (s.state.showMenuOverlay) s.toggleMenuOverlayAction();
  });

  objectFitImages();

  function hasClass(element, className) {
    return (` ${element.className} `).indexOf(` ${className} `) > -1;
  }

  document.addEventListener('lazyloaded', (e) => {
    if (s.debug) console.log('lazyloaded', e, e.target);
    if (hasClass(e.target, 'fit-cover')) {
      if (s.debug) console.log('objectFitImages');
      objectFitImages(e.target);
    }
  });

  if (document.querySelectorAll('.js-letter').length) {
    if (s.debug) console.log('Load AlphabetNav');
    const getAlphabetNav = import('./components/AlphabetNav/index');
    getAlphabetNav.then((AlphabetNav) => {
      AlphabetNav.default({
        alphaEl: '.js-letter',
        nameEl: '.js-letter-names',
      });
    });
  }

  if (document.querySelector('.js-contact-form')) {
    if (s.debug) console.log('Load ContactFormHandler');
    const getContactFormHandler = import('./components/ContactFormHandler/index');
    getContactFormHandler.then((ContactFormHandler) => {
      ContactFormHandler.default({
        form: '.js-contact-form',
        field: '.js-contact-form__field',
        label: '.js-contact-form__field--label',
      });
    });
  }

  if (document.querySelectorAll('dialog').length) {
    if (s.debug) console.log('Load Dialog');
    const getDialog = import('./components/Dialog/index');
    getDialog.then((Dialog) => {
      Dialog.default({
        els: 'dialog',
      });
    });
  }

  if (document.querySelectorAll('.js-page-overlay').length) {
    if (s.debug) console.log('Load PageOverlay');
    const getPageOverlay = import('./components/PageOverlay/index');
    getPageOverlay.then((PageOverlay) => {
      PageOverlay.default({
        els: '.js-page-overlay',
        toggle: '.js-page-overlay--toggle',
      });
    });
  }

  if (document.querySelectorAll('.js-phone-number').length) {
    if (s.debug) console.log('Load PhoneNumberToggle');
    const getPhoneNumberToggle = import('./components/PhoneNumberToggle/index');
    getPhoneNumberToggle.then((PhoneNumberToggle) => {
      PhoneNumberToggle.default({
        el: '.js-phone-number',
        toggle: '.js-phone-number--toggle',
        tel: '.js-phone-number--tel',
      });
    });
  }

  if (document.querySelectorAll('.js-read-more').length) {
    if (s.debug) console.log('Load getReadMore');
    const getReadMore = import('./components/ReadMore/index');
    getReadMore.then((ReadMore) => {
      ReadMore.default({
        els: '.js-read-more',
        button: '.js-read-more--toggle',
        intro: '.js-read-more--intro',
      });
    });
  }

  if (document.querySelectorAll('[data-scrollto]').length) {
    if (s.debug) console.log('Load ScrollTo');
    const getScrollTo = import('./components/ScrollTo/index');
    getScrollTo.then((ScrollTo) => {
      ScrollTo.default({
        el: '[data-scrollto]',
        navTarget: '.js-letter-names',
        navEl: '.js-letter',
      });
    });
  }

  if (document.querySelectorAll('.js-lazy-loading-search').length) {
    if (s.debug) console.log('Load SearchLazyLoading');
    const getSearchLazyLoading = import('./components/SearchLazyLoading/index');
    getSearchLazyLoading.then((SearchLazyLoading) => {
      SearchLazyLoading.default({
        el: '#search-lazy-loading-',
      });
    });
  }

  if (document.querySelector('.js-sticky')) {
    if (s.debug) console.log('Load StickyElements');
    const getStickyElements = import('./components/StickyElements/index');
    getStickyElements.then((StickyElements) => {
      StickyElements.default({
        el: '.js-sticky',
      });
    });
  }

  if (document.querySelectorAll('[data-video]').length) {
    if (s.debug) console.log('Load VideoContainer');
    const getVideoContainer = import('./components/VideoContainer/index');
    getVideoContainer.then((VideoContainer) => {
      VideoContainer.default({
        container: '[data-video]',
      });
    });
  }

  if (document.querySelectorAll('.js-video-container').length) {
    if (s.debug) console.log('Load VideoController');
    const getVideoController = import('./components/VideoController/index');
    getVideoController.then((VideoController) => {
      VideoController.default({
        container: '.js-video-container',
        video: '.js-video-container--video',
        muteBtn: '.js-video-container--mute',
        videoTagline: '.js-video-container--tagline',
      });
    });
  }

  if (document.querySelectorAll('.js-player').length) {
    Plyr.setup('.js-player', {
      loadSprite: false,
      speed: { selected: 1, options: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2] },
    });
  }

  Dropnav();
  Sliders();
  AnimateLinks();
  MapLink();
  HeaderFix();
  CopyLink();
  ToggleHeaderEntries();
});
// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
/* if (module.hot) {
  module.hot.accept();
} */
/*
if (import.meta.hot) {
  import.meta.hot.accept()
}
*/
