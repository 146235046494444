import anime from 'animejs';

export const HandleMenuOverlayEnter = (el, done, animateOverlay = true) => {
  // Animate overlay
  if (animateOverlay) {
    anime({
      targets: el,
      opacity: [0, 1],
      translateY: [200, 0],
      duration: 400,
      easing: 'easeOutCubic',
      complete: done,
    });
  }

  // Animate menu items
  const children = el.querySelectorAll('[data-child]');
  anime({
    targets: children,
    opacity: [0, 1],
    translateY: [48, 0],
    duration: 200,
    easing: 'easeOutCubic',
    delay: function (el, i) {
      return 100 + i * 20;
    },
    complete: function (el) {
      // Remove style attribute to allow proper subentry-nav styling
      el.animatables.forEach((item) => item.target.removeAttribute('style'));
    },
  });

  document.body.classList.add('overflow-hidden');
};

export const HandleMenuOverlayLeave = (el, done) => {
  anime({
    targets: el,
    opacity: [1, 0],
    duration: 300,
    easing: 'easeOutCubic',
    complete: done,
  });

  document.body.classList.remove('overflow-hidden');
};
