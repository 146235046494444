export default () => {
  const cutTextareaBtn = document.querySelector('.js-textareacutbtn');
  // eslint-disable-next-line
  if (cutTextareaBtn != null) {
    cutTextareaBtn.addEventListener('click', function () {
      const cutTextarea = document.querySelector('.js-cuttextarea');
      cutTextarea.select();

      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        const cutTextInfo = document.querySelector('.js-cuttextinfo');
        cutTextInfo.classList.add('active');
        setTimeout(function () {
          cutTextInfo.classList.remove('active');
        }, 2500);
        console.log(`Cutting text command was ${msg}`);
      } catch(err) {
        console.log('Oops, unable to cut');
      }
    });
  }
};
