export default () => {
  const header = document.querySelector('.js-header-hide-fix');
  const headerTop = document.querySelector('.header-top');
  // eslint-disable-next-line
  if (header != null && headerTop != null) {
    const headerTopHeight = headerTop.clientHeight;
    let scrollPos = 0;
    window.addEventListener('scroll', function () {
      const st = document.documentElement.scrollTop || document.body.scrollTop;

      if (st > scrollPos && scrollPos > headerTopHeight) {
        // header.classList.add("js-header-hide-scroll");
        header.style.top = `-${headerTopHeight}px`;
      } else {
        // header.classList.remove("js-header-hide-scroll");
        header.style.top = '0';
      }
      scrollPos = st;
    });
  }
};
